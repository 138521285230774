.customWord {
    text-shadow: 5px 5px 10px rgba(20, 37, 84, 0.55);
}

.triangoloEq {
    width: 0;
    height: 0;
    border-left: 0px solid transparent;
    border-right: 94px solid transparent;
    border-bottom: 54px solid #edeff1;
    transform: translate(0px, 32px)
}
